<template>
  <v-container fluid>
    <subHeader />
    <v-container fluid style="max-width: 1266px; overflow: hidden;">
      <v-row class="d-flex justify-center text-center">
        <iframe v-if="$route.path.includes('Week3')" src="https://x.wayin.com/display/container/dc/c5c4713b-d5a6-4a0c-a5c6-86dba7ad4e48?mode=adaptive" frameborder="0" scrolling="no" width="100%" height="735" id="ngxFrame21497e20-ecbe-4749-a01e-e2a2437af9a2" allowTransparency="true"></iframe>
        <iframe v-else src="https://x.wayin.com/display/container/dc/21497e20-ecbe-4749-a01e-e2a2437af9a2?mode=adaptive" frameborder="0" scrolling="no" width="100%" height="735" id="ngxFrame21497e20-ecbe-4749-a01e-e2a2437af9a2" allowTransparency="true"></iframe>
      </v-row>
    </v-container>
  </v-container>
</template>

<style scoped>
h2 {
  font-family: "AirNZ Sans Regular";
  font-size: 22px;
}

p {
  margin-top: 18px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}

li {
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}

a,
li a {
  margin-top: 15px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
  text-decoration: underline;
  color: #440099;
}

a:hover {
  color: #9b4ff7 !important;
}
</style>

<script>
import subHeader from "./subHeader.vue";

export default {
  components: {
    subHeader,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  mounted: function () {
    window.scrollTo(0, 0);
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>
