<template>
	<v-app>
		
		<!-- Disabled for admin preview -->
		<v-navigation-drawer v-if="$store.state.principal.administrator == false" style="box-shadow: unset; z-index: 999" :width="mini ? '43' : ''" right app v-model="drawer" :mini-variant.sync="mini" absolute temporary hide-overlay>
			<v-list>
				<v-container class="pb-4">
					<v-row class="justify-space-between" @click="drawer = !drawer">
						<v-col class="d-flex justify-start align-center pl-4">
							<img src="/icon/logo-airnz.svg" width="20"  />
						</v-col>
						<v-col class="d-flex justify-end align-center pr-4">
							<v-icon style="color: white !important" class="icon-duo-close" size="12"></v-icon>
						</v-col>
					</v-row>
				</v-container>
				
				<v-list-item @click="showProfile = false" style="color: white !important" to="/dashboard" link>
					<v-list-item-icon>
						<v-icon style="color: white !important" class="icon-duo-dashboard"></v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Home</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item @click="showProfile = false" style="color: white !important" to="/sales" link>
					<v-list-item-icon>
						<v-icon style="color: white !important" class="icon-anz-airplane"></v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title> <b>duo</b>sales </v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item @click="showProfile = false" style="color: white !important" to="/training" link>
					<v-list-item-icon>
						<v-icon style="color: white !important" class="duo-icon-training"></v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title> <b>duo</b>training </v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item @click="showProfile = false" style="color: white !important" to="/resources" link>
					<v-list-item-icon>
						<v-icon style="color: white !important" class="icon-briefcase"></v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title> <b>duo</b>marketing </v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item @click="showProfile = false" style="color: white !important" to="/profile" link>
					<v-list-item-icon>
						<v-icon style="color: white !important" class="icon-anz-person"></v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title> <b>duo</b>profile </v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item @click="logout" style="color: white !important" to="#" link>
					<v-list-item-icon>
						<v-icon style="color: white !important" class="icon-duo-exit"></v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Log out</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
			<template v-slot:append>
				<div style="width: 200px;"></div>
			</template>
		</v-navigation-drawer>

		<v-main :style="{
				paddingBottom: '0px',
				display: 'flex',
				flexDirection: 'column',
			}" class="pt-0 mt-0" v-if="$router.currentRoute.path.startsWith('/page/')">
			<router-view :key="$route.fullPath"></router-view>
		</v-main>
		<v-main
			v-else
			:style="{
				paddingBottom: '0px',
				backgroundImage: 'url(' + backgroundPicture() + ')',
				backgroundSize: 'cover',
				backgroundPosition: backgroundPicture().includes('AgencySupport.BG.jpg') ? 'center top' : 'center bottom',
				backgroundAttachment: 'fixed',
				minHeight: '100vh',
				display: 'flex',
				flexDirection: 'column',
				backgroundColor: ($route.fullPath.includes('/sweetStake') || $route.fullPath.includes('/sweetStakeTC') || this.$router.currentRoute.path.includes('/sweetStakeWayin') || this.$router.currentRoute.path.includes('/christmasSpinWin')) ? 'black' : ''
			}"
		>
			<v-container fluid>
				<v-row>
					<v-col cols="12" class="d-flex">
						<div v-if="$router.currentRoute.path != '/faqs'" class="navbar d-flex flex-wrap pt-5">
							<img v-if="this.$router.currentRoute.path.includes('/training/module') || this.$router.currentRoute.path.includes('/sweetStake') || this.$router.currentRoute.path.includes('/sweetStakeTC') || this.$router.currentRoute.path.includes('/sweetStakeWayin') || this.$router.currentRoute.path.includes('/christmasSpinWin')" style="width: 216px" class="pl-10" :src="$bucket + '/image/logo/logo-duo-reverse-white_color.svg'" @click="$store.commit('debug')" />
							<img v-else style="width: 216px" class="pl-10" :src="$bucket + '/image/logo-duo-reverse.svg'" @click="$store.commit('debug')" />
						</div>
						<div v-else class="navbar d-flex flex-wrap pt-5">
							<img style="width: 216px" :src="$bucket + '/image/logo/logo-duo-reverse-white_color.svg'" @click="$store.commit('debug')" />
							<img style="width: 182px" :src="$bucket + '/image/logo/logo-airnz-white_color.svg'" />
						</div>
						<v-spacer></v-spacer>
						<img src="/icon/logo-airnz.svg" class="anz-logo pt-6" :style="(this.$router.currentRoute.path.includes('/sweetStake') || this.$router.currentRoute.path.includes('/sweetStakeTC') || this.$router.currentRoute.path.includes('/sweetStakeWayin')) ? 'filter:none;':''" />
						<v-icon @click="drawer = !drawer" :style="(this.$router.currentRoute.path.includes('/sweetStake') || this.$router.currentRoute.path.includes('/sweetStakeTC') || this.$router.currentRoute.path.includes('/sweetStakeWayin') || this.$router.currentRoute.path.includes('/christmasSpinWin')) ? 'color: white !important':'color: black !important'" class="pt-6 pr-2" size="40">mdi-menu</v-icon>
					</v-col>
				</v-row>
				<router-view :key="$route.fullPath"></router-view>
			</v-container>
			<v-container style="margin-top:50px;">
				<duoFooter />
			</v-container>
		</v-main>

		<notification></notification>

		<img v-show="$root.loader.value" src="loader.svg" class="loader" />
	</v-app>
</template>

<style scoped>
.v-navigation-drawer--mini-variant .logo-label-airnz {
	display: none;
}

.v-navigation-drawer--is-mouseover .logo-airnz {
	display: none;
}

a.v-list-item {
	padding: 0 !important;
	height: 56px;
}
.v-app-bar {
	background: rgba(255, 255, 255, 0) !important;
}
.navbar {
	display: flex;
	justify-content: space-between !important;
	align-items: center;
	padding: 0 10px;
	width: 100%;
}
.v-navigation-drawer {
	height: 100% !important;
	position: fixed;
	background: #2d2926;
}
.v-list > .v-list-item:hover,
.v-list > .v-list-item:active {
	background-color: #440099 !important;
	text-decoration: none !important;
}
.v-list-item > .v-list-item__icon {
	margin-top: 20px;
}
.v-list-item:hover > .v-list-item__icon {
	background-color: #221551 !important;
	min-width: 56px;
	height: 56px;
	padding-right: 15px !important;
	margin: 0 13px 0 auto !important;
}
.v-list-item--link:before,
.v-list-item--active {
	background-color: #440099;
}
.v-list-item--active > .v-list-item__icon {
	background-color: #221551 !important;
	min-width: 56px;
	height: 56px;
	padding-right: 15px !important;
	margin: 0 13px 0 auto !important;
}
.v-list-item__icon > i {
	margin-left: 20px;
	font-size: 17px;
}
.v-application .primary--text {
	color: white !important;
	caret-color: white;
}
a.v-list-item:hover {
	text-decoration: none !important;
	background-color: #440099 !important;
}
a:hover {
	text-decoration: none !important;
	color: #440099 !important;
	background: none;
}
i:hover {
	text-decoration: none !important;
}
.anz-logo{
	padding-right: 5px;
	width:30px;
	filter: invert(88%) sepia(21%) saturate(935%) hue-rotate(123deg) brightness(85%) contrast(97%);
}
</style>

<script>
import duoFooter from "../duoFooter.vue";
import notification from "../notification.vue";

export default {
	components: {
		duoFooter,
		notification
	},
	data() {
		return {
			drawer: false,
			showProfile: false,
			mini: false,
		};
	},
	watch: {
		mini: function (o) {
			if (o) {
				this.showProfile = false;
			}
		},
	},
	methods: {
		backgroundPicture() {
			if (this.$router.currentRoute.path == "/dashboard") {
				return this.$bucket + "/image/background/AirNZ.BG.jpg";
			} else if (this.$router.currentRoute.path == "/am" || this.$router.currentRoute.path == "/TradeToolkit" || this.$router.currentRoute.path == "/TimeOutBeforeTakeOff" || this.$router.currentRoute.path == "/MTATravelAugustIncentive" || this.$router.currentRoute.path == "/CreditsAndRefunds" || this.$router.currentRoute.path.includes("/SeatsToSuit")  || this.$router.currentRoute.path == "/EconomyStretch" || this.$router.currentRoute.path == "/travelManagersConference" || this.$router.currentRoute.path == "/BoundForTheBigApple" || this.$router.currentRoute.path == "/cabinOfPossibility"  || this.$router.currentRoute.path == "/newYork" || this.$router.currentRoute.path == "/discoverNorthAmerica" || this.$router.currentRoute.path == "/discoverAustralia" || this.$router.currentRoute.path == "/takeFlightWithDuo" || this.$router.currentRoute.path == "/winYourWayToNewYorkState" || this.$router.currentRoute.path == "/mtaTravelIncentive" || this.$router.currentRoute.path == "/travelManagersIncentive" || this.$router.currentRoute.path == "/CreativeLab" || this.$router.currentRoute.path == "/honoluluBookAndWin" || this.$router.currentRoute.path == "/honoluluTC" || this.$router.currentRoute.path == "/HangarFTG" || this.$router.currentRoute.path == "/HangarWT" || this.$router.currentRoute.path == "/singapore" || this.$router.currentRoute.path == "/singaporeTC") {
				return this.$bucket + "/image/background/BDM.Map.BG.jpg";
			} else if (this.$router.currentRoute.path == "/sales" || this.$router.currentRoute.path.includes("/resources")) {
				return this.$bucket + "/image/background/MySales.BG.jpg";
			} else if (this.$router.currentRoute.path == "/profile/personal" || this.$router.currentRoute.path == "/profile/company" || this.$router.currentRoute.path == "/profile/accountsetting") {
				return this.$bucket + "/image/background/EditDetails.BG.jpg";
			} else if (this.$router.currentRoute.path == "/agency") {
				return this.$bucket + "/image/background/AgencySupport.BG.jpg";
			} else if (this.$router.currentRoute.path == "/profile") {
				return this.$bucket + "/image/background/Profile.BG.jpg";
			} else if (this.$router.currentRoute.path == "/faqs") {
				return this.$bucket + "/image/background/FAQ.BG.jpg";
			} else if (this.$router.currentRoute.path == "/virtualCard") {
				return this.$bucket + "/image/background/Virtual.Card.BG.jpg";
			} else if (String(this.$router.currentRoute.path).startsWith("/training") || this.$router.currentRoute.path == "/seeMoreNZ" || this.$router.currentRoute.path == "/destinationDash") {
				return this.$bucket + "/image/background/Training.BG.jpg";
			} else if (this.$router.currentRoute.path == "/resources") {
				return this.$bucket + "/image/background/Virtual.Card.BG.jpg";
			} else if (this.$router.currentRoute.path == '/sweetStake' || this.$router.currentRoute.path == '/sweetStakeTC' || this.$router.currentRoute.path == '/sweetStakeWayin' || this.$router.currentRoute.path == '/christmasSpinWin') {
				return '';
			}
			return this.$bucket + "/image/" + "background-violet.jpg";
		},
		logout() {
			this.$store
				.dispatch("logout")
				.then(() => {
					this.$router.push("/login");
				})
				.catch(() => {});
		}
	},
};
</script>
