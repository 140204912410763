<template>
	<div style="margin: 15.69px 0px 25px 0px" :style="dynamicStyle">
		<div class="subHeader-profile d-flex flex-row flex-wrap justify-start text-left">
			<div>
				<v-btn @click="handleInput(0)" :class="selectedValue == 0 ? 'selected-tab':''" text tile style="height: 35px;">
					<v-row no-gutters justify="center" class="top-navigation-logged-in px-4" style="letter-spacing: 0; font-size: 14px; text-transform: none"> <b>Retail & Account</b>&nbsp;Managers </v-row>
				</v-btn>
				<v-btn @click="handleInput(1)" :class="selectedValue == 1 ? 'selected-tab':''" text tile style="margin-right: 10px; height: 35px;">
					<v-row no-gutters justify="center" class="top-navigation-logged-in px-4" style="letter-spacing: 0; font-size: 14px; text-transform: none"> <b>Sales</b>&nbsp;Managers </v-row>
				</v-btn>
				<v-btn @click="handleInput(2)" :class="selectedValue == 2 ? 'selected-tab':''" text tile style="height: 35px;">
					<v-row no-gutters justify="center" class="top-navigation-logged-in px-4" style="letter-spacing: 0; font-size: 14px; text-transform: none"> Training & Engagement </v-row>
				</v-btn>
				<v-btn @click="handleInput(3)" :class="selectedValue == 3 ? 'selected-tab':''" text tile style="height: 35px;">
					<v-row no-gutters justify="center" class="top-navigation-logged-in px-4" style="letter-spacing: 0; font-size: 14px; text-transform: none"> Sales Specialist&nbsp;<b>Groups</b> </v-row>
				</v-btn>
			</div>
		</div>
		<v-divider :class="'duo-subheader'"></v-divider>
	</div>
</template>

<style scoped>
.selected-tab {
  border-bottom: 3px solid #2D2926; /* Add your desired border style here */
}
.top-navigation-logged-in {
	font-size: 12px;
}
.v-btn:not(.v-btn--round).v-size--default {
	height: 25px;
	min-width: 64px;
	padding: 0 0px !important;
}
.duo-subheader.v-divider {
	margin-bottom: 20px;
	border: var(--border);
	border-color: #2d2926;
}
.duo-subheader-invert.v-divider {
	margin-bottom: 20px;
	border: var(--border);
	border-color: #ffffff;
}
.v-btn {
	text-transform: capitalize;
	padding: 0 0 16px 0 !important;
	margin-right: 80px;
	display: inline-block !important;
	justify-content: left !important;
}
.v-btn:before {
	background-color: transparent !important;
}
.v-btn--active {
	border-bottom: 5px solid #2d2926;
	background-color: none !important;
}
a:hover {
	color: #2d2926 !important;
	background-color: none !important;
	text-decoration: none;
}
.v-btn--active {
	border-bottom: var(--border-bottom);
	background-color: none !important;
}
.v-btn:after {
	border-bottom: var(--border-bottom) !important;
	background-color: none !important;
}
</style>

<script>
/* eslint-disable no-console */
export default {
	prop: ['value'],
	data() {
		return {
			selectedValue: this.value || 0
		};
	},
	methods: {
		handleInput (index) {
			this.selectedValue = index;
			this.$emit('input', this.selectedValue)
		},
	},
	computed: {
		dynamicStyle() {
			return "--border: 0.7px solid #2D2926;";
		},
	},
};
</script>