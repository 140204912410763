<template>
  <v-main style="margin: 10px">
    <v-card class="d-flex flex-column align-content-center" min-height="155">
      <v-row no-gutters style="padding: 20px 10px 15px">
        <v-col cols="12" sm="8">
          <div class="d-flex px-2">
            <h2>Report</h2>
          </div>
        </v-col>
      </v-row>
      <v-card-text>
        <v-form ref="form" no-gutters class="d-flex flex-wrap">
          <v-row class="d-inline-flex">
            <v-col cols="3" class="date-block ma-2 px-5">
              <v-menu ref="dateRegisterStartPicker" v-model="dateRegisterStartMenu" :close-on-content-click="false"
                :return-value.sync="dateRegisterStart" transition="scale-transition" offset-y max-width="320px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field id="dateRegisterStart" :v-model="dateRegisterStart"
                    :value="$root.parseDate(dateRegisterStart)" readonly v-on="on" v-bind="attrs"
                    :rules="[(v) => !!v || 'Enter start register date']" @click:prepend="dateRegisterStartMenu = true">
                    <template v-slot:append>
                      <img v-on="on" v-bind="attrs" width="24" height="24" src="/icon/icon-calendar.svg" />
                    </template>
                    <template v-slot:label>
                      <span style="font-size: 17px; color: black !important">Start register date</span>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker v-model="dateRegisterStart" no-title scrollable style="width: 100%">
                  <v-row no-gutters justify="space-around" class="pa-4" style="min-width: 304px">
                    <v-btn width="120" class="mx-0" outlined color="primary"
                      @click="$refs.dateRegisterStartPicker.save(dateRegisterStart)">Confirm</v-btn>
                    <v-btn width="120" class="mx-0" outlined @click="dateRegisterStartMenu = false">Cancel</v-btn>
                  </v-row>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="3" class="date-block ma-2 px-5">
              <v-menu ref="dateRegisterEndPicker" v-model="dateRegisterEndMenu" :close-on-content-click="false"
                :return-value.sync="dateRegisterEnd" transition="scale-transition" offset-y max-width="320px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field id="dateRegisterStart" :v-model="dateRegisterEnd"
                    :value="$root.parseDate(dateRegisterEnd)" readonly v-on="on" v-bind="attrs"
                    :rules="[(v) => !!v || 'Enter end register date']" @click:prepend="dateRegisterEndMenu = true">
                    <template v-slot:append>
                      <img v-on="on" v-bind="attrs" width="24" height="24" src="/icon/icon-calendar.svg" />
                    </template>
                    <template v-slot:label>
                      <span style="font-size: 17px; color: black !important">End register date</span>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker v-model="dateRegisterEnd" no-title scrollable style="width: 100%">
                  <v-row no-gutters justify="space-around" class="pa-4" style="min-width: 304px">
                    <v-btn width="120" class="mx-0" outlined color="primary"
                      @click="$refs.dateRegisterEndPicker.save(dateRegisterEnd)">Confirm</v-btn>
                    <v-btn width="120" class="mx-0" outlined @click="dateRegisterEndMenu = false">Cancel</v-btn>
                  </v-row>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex flex-wrap">
        <v-btn medium class="mt-2 ml-0" color="primary" @click="downloadUsersLaunchCampaignPoint"><v-icon
            left>mdi-export</v-icon> Export Registered Agents & Points</v-btn>
        <v-btn medium class="mt-2 ml-0" color="primary" @click="downloadUsersRegistrationPoint('Pending')"><v-icon
            left>mdi-export</v-icon> Export Registered & Not Confirmed Agents</v-btn>
      </v-card-actions>
    </v-card>

    <v-card class="d-flex flex-column align-content-center mt-2" min-height="155">
      <v-card-text>
        <v-form ref="form2" no-gutters class="d-flex flex-wrap">
          <v-row>
            <v-col cols="3" class="date-block ma-2 px-5" style="min-width: 200px">
              <v-menu ref="dateActivityStartPicker" v-model="dateActivityStartMenu" :close-on-content-click="false"
                :return-value.sync="dateActivityStart" transition="scale-transition" offset-y max-width="320px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field id="dateActivityStart" :v-model="dateActivityStart"
                    :value="$root.parseDate(dateActivityStart)" readonly v-on="on" v-bind="attrs"
                    :rules="[(v) => !!v || 'Enter start activity date']" @click:prepend="dateActivityStartMenu = true">
                    <template v-slot:append>
                      <img v-on="on" v-bind="attrs" width="24" height="24" src="/icon/icon-calendar.svg" />
                    </template>
                    <template v-slot:label>
                      <span style="font-size: 17px; color: black !important">Start activity date</span>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker v-model="dateActivityStart" no-title scrollable style="width: 100%">
                  <v-row no-gutters justify="space-around" class="pa-4" style="min-width: 304px">
                    <v-btn width="120" class="mx-0" outlined color="primary"
                      @click="$refs.dateActivityStartPicker.save(dateActivityStart)">Confirm</v-btn>
                    <v-btn width="120" class="mx-0" outlined @click="dateActivityStartMenu = false">Cancel</v-btn>
                  </v-row>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="3" class="date-block ma-2 px-5" style="min-width: 200px">
              <v-menu ref="dateActivityEndPicker" v-model="dateActivityEndMenu" :close-on-content-click="false"
                :return-value.sync="dateActivityEnd" transition="scale-transition" offset-y max-width="320px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field id="dateActivityStart" :v-model="dateActivityEnd"
                    :value="$root.parseDate(dateActivityEnd)" readonly v-on="on" v-bind="attrs"
                    :rules="[(v) => !!v || 'Enter end activity date']" @click:prepend="dateActivityEndMenu = true">
                    <template v-slot:append>
                      <img v-on="on" v-bind="attrs" width="24" height="24" src="/icon/icon-calendar.svg" />
                    </template>
                    <template v-slot:label>
                      <span style="font-size: 17px; color: black !important">End activity date</span>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker v-model="dateActivityEnd" no-title scrollable style="width: 100%">
                  <v-row no-gutters justify="space-around" class="pa-4" style="min-width: 304px">
                    <v-btn width="120" class="mx-0" outlined color="primary"
                      @click="$refs.dateActivityEndPicker.save(dateActivityEnd)">Confirm</v-btn>
                    <v-btn width="120" class="mx-0" outlined @click="dateActivityEndMenu = false">Cancel</v-btn>
                  </v-row>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex flex-wrap">
        <v-btn medium class="mt-2 ml-0" color="primary" @click="downloadUsersActivityCampaignPoint"><v-icon
            left>mdi-export</v-icon> Export Agents Activity Points</v-btn>
      </v-card-actions>
    </v-card>

    <v-card class="d-flex flex-column align-content-center mt-2" min-height="155">
      <v-card-text>
        <v-form ref="form3" no-gutters class="d-flex flex-wrap">
          <v-row>
            <v-col cols="3" class="date-block ma-2 px-5" style="min-width: 200px">
              <v-menu ref="dateVisitStartPicker" v-model="dateVisitStartMenu" :close-on-content-click="false"
                :return-value.sync="dateVisitStart" transition="scale-transition" offset-y max-width="320px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field id="dateVisitStart" :v-model="dateVisitStart" :value="$root.parseDate(dateVisitStart)"
                    readonly v-on="on" v-bind="attrs" :rules="[(v) => !!v || 'Enter start visit date']"
                    @click:prepend="dateVisitStartMenu = true">
                    <template v-slot:append>
                      <img v-on="on" v-bind="attrs" width="24" height="24" src="/icon/icon-calendar.svg" />
                    </template>
                    <template v-slot:label>
                      <span style="font-size: 17px; color: black !important">Start visit date</span>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker v-model="dateVisitStart" no-title scrollable style="width: 100%">
                  <v-row no-gutters justify="space-around" class="pa-4" style="min-width: 304px">
                    <v-btn width="120" class="mx-0" outlined color="primary"
                      @click="$refs.dateVisitStartPicker.save(dateVisitStart)">Confirm</v-btn>
                    <v-btn width="120" class="mx-0" outlined @click="dateVisitStartMenu = false">Cancel</v-btn>
                  </v-row>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="3" class="date-block ma-2 px-5" style="min-width: 200px">
              <v-menu ref="dateVisitEndPicker" v-model="dateVisitEndMenu" :close-on-content-click="false"
                :return-value.sync="dateVisitEnd" transition="scale-transition" offset-y max-width="320px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field id="dateVisitStart" :v-model="dateVisitEnd" :value="$root.parseDate(dateVisitEnd)"
                    readonly v-on="on" v-bind="attrs" :rules="[(v) => !!v || 'Enter end visit date']"
                    @click:prepend="dateVisitEndMenu = true">
                    <template v-slot:append>
                      <img v-on="on" v-bind="attrs" width="24" height="24" src="/icon/icon-calendar.svg" />
                    </template>
                    <template v-slot:label>
                      <span style="font-size: 17px; color: black !important">End visit date</span>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker v-model="dateVisitEnd" no-title scrollable style="width: 100%">
                  <v-row no-gutters justify="space-around" class="pa-4" style="min-width: 304px">
                    <v-btn width="120" class="mx-0" outlined color="primary"
                      @click="$refs.dateVisitEndPicker.save(dateVisitEnd)">Confirm</v-btn>
                    <v-btn width="120" class="mx-0" outlined @click="dateVisitEndMenu = false">Cancel</v-btn>
                  </v-row>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="3">
              <v-autocomplete v-model="linksFilter" :items="visitLinks" item-text="value" item-value="value"
                label="Filter" :class="{ 'line-blue': visitLinks != null }" class=" rounded-0"
                append-icon="mdi-chevron-down" />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex flex-wrap">
        <v-btn medium class="mt-2 ml-0" color="primary" @click="downloadUsersTilesVisited"><v-icon
            left>mdi-export</v-icon> Export Agents Visited Links</v-btn>
      </v-card-actions>
    </v-card>
  </v-main>
</template>

<style scoped>
.date-block {
  max-width: 230px !important;
}
</style>

<script>
import axios from "axios";

export default {
  data() {
    return {
      dateRegisterStartMenu: false,
      dateRegisterStart: null,
      dateRegisterEndMenu: false,
      dateRegisterEnd: null,
      dateActivityStartMenu: false,
      dateActivityStart: null,
      dateActivityEndMenu: false,
      dateActivityEnd: null,
      dateVisitStartMenu: false,
      dateVisitStart: null,
      dateVisitEndMenu: false,
      dateVisitEnd: null,
      linksFilter: "",
      visitLinks: []
    };
  },
  mounted() {
    this.listAllLinks();
  },
  methods: {
    downloadUsersRegistrationPoint(status) {
      if (this.$refs.form.validate()) {
        window.open(axios.defaults.baseURL + "/system/person/point/registration/export?dateStart=" + this.dateRegisterStart + "&dateEnd=" + this.dateRegisterEnd + "&status=" + status);
      }
    },
    downloadUsersLaunchCampaignPoint() {
      if (this.$refs.form.validate()) {
        window.open(axios.defaults.baseURL + "/system/person/point/launch/export?dateStart=" + this.dateRegisterStart + "&dateEnd=" + this.dateRegisterEnd);
      }
    },
    downloadUsersActivityCampaignPoint() {
      if (this.$refs.form2.validate()) {
        window.open(axios.defaults.baseURL + "/system/person/point/activity/export?dateStart=" + this.dateActivityStart + "&dateEnd=" + this.dateActivityEnd);
      }
    },
    downloadUsersTilesVisited() {
      if (this.$refs.form3.validate()) {
        window.open(axios.defaults.baseURL + "/system/activitylog/tiles/export?dateStart=" + this.dateVisitStart + "&dateEnd=" + this.dateVisitEnd + "&value=" + (this.linksFilter.length > 0 ? encodeURIComponent(this.linksFilter.replaceAll(' ', '_')) : ''));
      }
    },
    listAllLinks() {
      axios
        .get("/system/activitylog/tiles/list")
        .then((response) => {
          this.visitLinks = response.data;
        })
        .catch(() => { });
    }
  }
};
</script>
