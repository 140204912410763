<template>
  <v-container fluid>
    <subHeader />
    <v-container fluid style="max-width: 1266px">
      <v-row justify="center">
        <v-col cols="12" style="padding: 0; margin-bottom: 14px">
          <v-img
            max-width="1266"
            min-height="345"
            style="background-size: cover; background-position: center center"
            :src="$bucket + '/image/dashboard-pages/ChristmasSpinWin.Landing.jpg'"
          />
        </v-col>
      </v-row>
      <v-row>
        <div style="width: 1266px !important; padding-bottom: 1rem">
          <h2 class="pt-8 pb-8 white--text">
            Find the magic with Air New Zealand's Spin & Win Adventure!
          </h2>
          <p class="white--text">
            Welcome to Air New Zealand's Spin & Win Adventure! Over the next 10 weekdays make sure you spin the wheel daily for your chance to unwrap an incredible range of Christmas gifts. And that's not all - each day brings an extra special grand prize, adding even more excitement to the festive season.
          </p>
          <p class="white--text">
            From international flights to sleek AirPods and luxe beauty treats - your winning journey starts here with over 100 presents to be won.
          </p>
          <p class="white--text">
            <b>Spin now and let the adventure take flight.</b>
          </p>
          <br /><br />
          <div class="white--text">
            <b>How to Play:</b>
            <li style="list-style-type: none;">
                <div style="width: 100%; float:left; margin-top: 10px;" >
                  <ol>
                    <li style="margin-top: 10px;">
                      <p style="margin-left: 20px;">
                        Log in: Simply click the "Enter Now" button to get started.
                      </p>
                    </li>
                    <li style="margin-top: 10px;">
                      <p style="margin-left: 20px;">
                        Fill in your details: Once you're in, fill in your details and get ready to spin the wheel.
                      </p>
                    </li>
                    <li style="margin-top: 10px;">
                      <p style="margin-left: 20px;">
                        Spin the wheel: With a spin, you'll either win no prize, a small present, or the daily big present. There are 10 small presents and 1 big present up for grabs each day. The prizes are randomly allocated.
                      </p>
                    </li>
                    <li style="margin-top: 10px;">
                      <p style="margin-left: 20px;">
                        Instant win notification: After your spin, you'll immediately know if you've won a small or big present. However, the exact present will remain a surprise for now.
                      </p>
                    </li>
                    <li style="margin-top: 10px;">
                      <p style="margin-left: 20px;">
                        Confirmation email: If you're a lucky winner, you'll see a notification on screen that you'll receive a confirmation email within the next 24 hours. Terms and Conditions apply.
                      </p>
                    </li>
                  </ol>
                </div>
                <br style="clear:both;"/>
            </li>
          </div>
          <br />
          <p style="font-size: 13px">
            <a
              class="white--text"
              style="font-size: 13px; color: black; font-style: italic"
              :href="$window.location.origin + '/#/page/christmasSpinWinTC'"
              target="_blank" 
              >Terms and conditions apply.</a
            >
          </p>
          <br />
        </div>
        <v-row class="justify-center py-12 mb-12">
          <a 
            :href="wayinLink"
            target="_blank"
          >
            <v-img
              max-width="180"
              min-height="145"
              style="background-size: cover; background-position: center center"
              :src="$bucket + '/image/dashboard-pages/ChristmasSpinWin.EnterNow.png'"
            />
          </a>
        </v-row>
      </v-row>
    </v-container>
  </v-container>
</template>

<style scoped>
h2 {
  font-family: "AirNZ Sans Regular";
  font-size: 22px;
}

p {
  margin-top: 18px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}

li {
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}

a,
li a {
  margin-top: 15px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
  text-decoration: underline;
  color: #440099;
}

a:hover {
  color: #9b4ff7 !important;
}
</style>

<script>
import subHeader from "./subHeader.vue";

export default {
  components: {
    subHeader,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  mounted: function () {
    window.scrollTo(0, 0);
  },
  data() {
    return {
    };
  },
  methods: {
  },
  computed: {
    wayinLink() {
      const isWeek3 = this.$route.path.includes('Week3');
      return `${window.location.origin}/#/${isWeek3 ? 'christmasWayinWeek3' : 'christmasWayin'}`;
    },
  },
};
</script>
